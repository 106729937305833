import React from 'react'
import { Link, useLocation,useParams } from 'react-router-dom';
import ContentBox from '../../components/contentBox/ContentBox'
import PhotoBox from '../../components/PhotoBox/PhotoBox';
import data from "../../data";
import { useEffect,useState } from 'react';
import axios from 'axios';

import "./ProjectDetail.styles.scss"

const ProjectDetail = ({lang}) => {
    const [projects, setProjects] = useState([]);
    const { id } = useParams();
    const product = data[id];
    const location = useLocation();
    const localLang = localStorage.getItem('lang');
  
  useEffect(() => {
     axios.get('/projects.json')
    .then((res)=>{
      setProjects(res.data);
    }
    ).catch((err)=>{
     console.log(err);
    }
     )
  }, [])


  return (
      <section className='pd-detail'>
      <div className='fixed-wrap'>
        <div className='columns'>
            {

            }
                  <Link to={`/projects/detail/${product.id}/slider`}> <PhotoBox cls="center bdr-bottom0" imgUrl={product.cover} title={"RaumGlück"} /></Link>
                  
                  {
                  lang == "en" ?
                  <ContentBox
                    heading={product.tagline}
                    desc={product.desc}
                    cls={"shape bottom-shape"}
                    />
                    :
                    <ContentBox
                          heading={product.DE_tagline}
                          desc={product.DE_des}
                          cls={"shape bottom-shape"}
                    />
            
                   } 
         
          </div>
      </div>
    </section>
  )
}

export default ProjectDetail