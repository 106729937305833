import React from 'react'
import "./Contact.style.scss"
import ContentBox from '../../components/contentBox/ContentBox'
import PhotoBox from '../../components/PhotoBox/PhotoBox';
import { BsFacebook,BsInstagram } from "react-icons/bs";
import { useTranslation } from 'react-i18next'
const ContactUs = () => {
  const { t, i18n } = useTranslation();
  return (
     <section className='contact-us p-b-50'>
    
         <div className='columns'>
        <div class="contentBox shape left-shape">
          <div class="content">
            <h2>Dr.techn Martina Maria Spies <span class="sub-head"></span></h2>
            <h4><a href="mailto:studio@raumglueck.at">studio@raumglueck.at</a></h4>
            <p>
              <div>
                <a href="tel:+436503883355">+43 6503883355</a><br />
                <a href="https://goo.gl/maps/DvwdApxYQ2hzrr7P9" target="_blank">{t('CONTACT_US_DE')}</a>

                <div class="spacer"></div>

                <a href="tel:+919167555882">+91 9167555882</a><br />
                <a href="https://goo.gl/maps/4VgbwS2yxdTFQqva9" target="_blank">{t('CONTACT_US_IND')}</a>
              </div>
            </p>
            <div className='social-wrap'>
              <a href="https://m.facebook.com/raumglueckdesign/" target="_blank"><BsFacebook /></a>
              <a href="https://www.instagram.com/raumglueckdesign/" target="_blank"><BsInstagram /></a>
            </div>
            
          </div>
         </div>
       
        <PhotoBox cls="left shape shape-right" imgUrl={require("../../assets/contact-img.png")} title={"RaumGlück"} />  


 
          </div>
     
    </section>
  )
}

export default ContactUs