import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
        'PROJECTS':'PROJECTS',
        'ABOUT ME': 'ABOUT ME',
        'ABOUT US': 'ABOUT US',
        'TEAM': 'TEAM',
        'CONTACT': 'CONTACT',
        'HOME_HEADING': '"Every human being is different. Every space is different."',
        'HOME_AUTHOR': 'Martina Maria Spies (Founder)',
        'ABOUT_ME_DES': 'I am an Austrian architect, designer and builder working in Austria and India. I studied at the University of Applied Arts in Vienna under the guidance of Zaha Hadid, Hans Hollein and Greg Lynn, and also at The Oslo School of Architecture. My work has taken me through Austria and numerous other places such as Japan, Hong Kong and India: After my doctoral thesis on Dharavi - one of the most complex informal settlements in the world in the middle of Mumbai - I founded the organisation "Anukruti" to create playgrounds for underprivileged children out of recycled materials, called “Urban Flowers” (www.anukruti.org).\n As a cosmopolitan and empathatic architect I have specialized in the entire design and construction process - from the first spatial concept to individual details for a successful and happy design.',
        'ABOUT_ME_HEADING': 'Dr.techn Martina Maria Spies',
        'ABOUT_ME_SUBHEADING': '(Principal Architect // RaumGlück)',
        'ABOUT_ME_HEADING_2': 'Hannah Speer',
        'ABOUT_ME_DES_2': 'Helping shape the environment we experience is a great responsibility for our togetherness. It determines how people meet, behave and where they want to be. It is necessary to have an individual solution for every construction task. This is implemented at RaumGlück. Architecture has to constantly adapt and create an interplay between people and the environment. With my love for details, sensitivity for design and the authenticity of ideas, I support RaumGlück with all their projects.',
        'ABOUT_ME_SUBHEADING_2': '(Intern // Student at\n The Vienna University of Technology)',
        'What RaumGlück': 'What RaumGlück is.',
        'What RaumGlück des': 'Architecture is much more than just planning and building. A space becomes a living space and, at best, a dream of life, when it responds to the needs of the people who live in it. Their life story and personality are reflected in the space; and in turn the space reflects the owners in a similar way. Thus creating a cycle of RaumGlück.',
        'Origins of RaumGlück': 'Where RaumGlück origins from.',
        'Origins of RaumGlück des': 'The passionate and cosmopolitan architect, designer, and builder Martina Spies has founded her own studio called RaumGlück. She is the mastermind behind Raumglück.',
        'How RaumGlück works': 'How RaumGlück works.',
        'How RaumGlück works des': 'Responding individually with personal attention to clients and their lifestyle requirements is the key to create RaumGlück. Developing ideas, planning, building and managing construction sites, all from one single source.',
        'What RaumGlück offers': 'What RaumGlück offers.',
        'What RaumGlück offers des': 'Rebuilding, renovating and designing interior and exterior spaces. RaumGlück operates throughout Austria and other European countries as well as in India. We have a network of reliable craftsmen to support project execution.',
        'CONTACT_US_DE': 'Ödenkirchen 8\n 2931 Warth im Wechselgebiet',
        'CONTACT_US_IND': '402, Juhu Anmol\n 400049 Mumbai',
        'NEEL_INFO':'Designed and developed by'
        
    }
  },
  de: {
    translation: {
        'PROJECTS':'PROJEKTE',
        'ABOUT ME':'ÜBER MICH',
        'ABOUT US':'ÜBER UNS',
        'TEAM':'TEAM',
        'CONTACT':'KONTAKT', 
        'HOME_HEADING':',,Jeder Mensch ist anders. Jeder Raum ist anders."',
        'HOME_AUTHOR': 'Martina Maria Spies (Gründerin)',
        'ABOUT_ME_DES': 'Ich bin Architektin und Baumeisterin, die sowohl in Österreich als auch in Indien tätig ist. Studiert habe ich an der Universität für Angewandte Kunst in Wien bei Zaha Hadid, Hans Hollein and Greg Lynn und an der „Oslo School of Architecture“. Meine Arbeit hat mich bisher durch Österreich und zahlreiche andere Orte wie Japan, Hongkong und Indien geführt: Nach meiner Doktorarbeit über Dharavi - einer der komplexesten informellen Siedlungen der Welt inmitten von Mumbai - habe ich die Organisation „Anukruti“ gegründet, um Spielplätze in informellen Siedlungen aus Recyclingmaterialien, „Stadtblumen“ genannt, zu bauen (www.anukruti.org).\n Als weltoffene und empathische RaumGlück Architektin und Glückstrainerin in Ausbildung habe ich mich auf den gesamten Design- und Bauprozess spezialisiert – vom ersten (Raum-) Konzept bis hin zu maßgeschneiderten Details für eine gelungene Raumgestaltung.',
        'ABOUT_ME_HEADING': 'Dr.techn Martina Maria Spies ', 
        'ABOUT_ME_SUBHEADING':'(Gründerin // RaumGlück)',
        'ABOUT_ME_HEADING_2':'Hannah Speer', 
        'ABOUT_ME_DES_2':'Die erlebte Welt mitzugestalten, ist eine große Verantwortung für unser Miteinander. Es bestimmt, wie sich Menschen begegnen, verhalten und wo sie sich treffen. Das erfordert eine sehr individuelle Lösung für jede Bauaufgabe. Genau das wird bei RaumGlück umgesetzt.  Architektur muss sich ständig neu anpasssen und bildet ein Wechselspiel zwischen Menschen und Umwelt. Mit meiner Liebe zum Detail, meiner Sensibilität zur Gestaltung und der Authentizität im Entwurf unterstütze ich RaumGlück bei allen individuellen Bauaufgaben.',
        'ABOUT_ME_SUBHEADING_2':'Praktikantin // Studentin der Architektur an der TU\n Wien',
        'What RaumGlück':'Was RaumGlück ist.',
        'What RaumGlück des': 'Architektur ist nicht nur planen und bauen. Es ist viel mehr. Ein Raum wird erst zum Lebensraum und im besten Fall Lebens-Traum, wenn er auf die Bedürfnisse der Menschen, die in ihm leben, eingeht. Die Lebensgeschichte aber auch die Persönlichkeit des Menschen spiegelt sich im Raum wieder. Genauso wirkt aber auch der Raum auf den Menschen. So entsteht RaumGlück.',
        'Origins of RaumGlück': 'Wie RaumGlück entstand.',
        'Origins of RaumGlück des': 'Die Architektin aus Leidenschaft, Baumeisterin und Weltenbürgerin Martina Spies hat ihr eigenes Bau-Atelier gegründet. Es trägt den Namen RaumGlück. Martina Spies ist die RaumglückArchitektin.',
        'How RaumGlück works': 'Wie RaumGlück arbeitet.',
        'How RaumGlück works des': 'Individuell und persönlich auf die Menschen und ihre Vorstellungen wie sie wohnen möchten, eingehen, das sind die Schlüsselfaktoren zum RaumGlück. Ideen entwickeln, planen, bauen und die Baustelle managen – alles aus einer Hand. Ein Netzwerk an verlässlichen HandwerkerInnen unterstützt dabei.',
        'What RaumGlück offers': 'Was Raumglück anbietet.',
        'What RaumGlück offers des': 'Neu- oder umbauen, sanieren und Innen- und Außenräume gestalten. RaumGlück ist österreichweit und in Indien tätig.',
        'CONTACT_US_DE': 'Ödenkirchen 8\n 2831 Warth im Wechselgebiet',
      'CONTACT_US_IND': '402, Juhu Anmol\n 400049 Mumbai',
        'NEEL_INFO':'Entwickelt und entworfen von'
        
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;