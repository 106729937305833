import React from 'react'
import ContentBox from '../../components/contentBox/ContentBox'
import { useTranslation } from 'react-i18next'
import "./Home.style.scss"
const Home = () => {
  const { t, i18n } = useTranslation()

  return (
    
    <div className='home fixed-wrap'>
      
      <ContentBox heading={t('HOME_HEADING')} author={t('HOME_AUTHOR')} cls={"shape bottom-shape"}/>
      </div>
  )
}

export default Home