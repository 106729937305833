import React from 'react'
import { Link } from 'react-router-dom';
import PhotoBox from '../../components/PhotoBox/PhotoBox';
import data from "../../data/";
import "./Project.style.scss"
import { useEffect,useState } from 'react';
import axios from 'axios';


const Projects = ({lang}) => {
  
  const [projects, setProjects] = useState([]);

  useEffect(() => {
     axios.get('/projects.json')
    .then((res)=>{
      setProjects(res.data);
    }
    ).catch((err)=>{
     console.log(err);
    }
    )
  }, [])
  

  return (
    <div className='project-wrapper'>
      <ul className='project-list'>
      {
        data.map((project, id) => (
          <li className=''>
            <Link to={`/projects/detail/${id}`} className='content'>
              <p className='numb'>0{ id + 1}</p>
              {
                  lang == "en" ?
                  <h3>{project.name}</h3>
                  :
                  <h3>{project.DE_name}</h3>
               }
            </Link>
            <PhotoBox cls="project" imgUrl={project.img}/>
          </li>
        ))
        }
      </ul>
    </div>
  )
}

export default Projects;