import React from 'react'
import { useLocation } from 'react-router-dom';
import data from "../../data"
import ResizeImg from "../../assets/1x1.png"
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { EffectFade, Navigation } from "swiper";
import { useEffect,useState } from 'react';
import "./ProductSlider.styles.scss"

const ProductSlider = ({lang}) => {
    const [projects, setProjects] = useState([]);
    const location = useLocation();
    
    const path = location.pathname.split("/")[3];
    const { sliders } = data[path];
    
    
    
  
    useEffect(() => {
     axios.get('/projects.json')
    .then((res)=>{
      setProjects(res.data);
      
      
    }
    ).catch((err)=>{
     console.log(err);
    }
    )
    }, [])
  

  return (
      <section className='slide-sec'>
          <Swiper className="mySwiper" spaceBetween={30}
        effect={"fade"}
        navigation={true}
       
        modules={[EffectFade, Navigation]}>
              {
            sliders.map((slide, ind) => <SwiperSlide><img src={ResizeImg} style={{backgroundImage:`url(${slide})`}}/></SwiperSlide>)
            }
      </Swiper>
      {
        lang == "en" ?
          <h4 className='slide-title text-center'>{data[path].name}</h4>
          :
          <h4 className='slide-title text-center'>{data[path].DE_name}</h4>
      }
      </section>
  )
}

export default ProductSlider