import React from 'react'
import "./AboutUs.style.scss"
import ContentBox from '../../components/contentBox/ContentBox'
import PhotoBox from '../../components/PhotoBox/PhotoBox';
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
   const { t, i18n } = useTranslation()
  return (
     <section className='about-us p-b-50'>
     
        <div className='columns'>
        <ContentBox
          heading={t('What RaumGlück')}
          desc={t('What RaumGlück des')}
                  
          cls={"shape bottom-shape about-us"}
        />
          <PhotoBox cls="center bdr-tb-0  shape shape-left" imgUrl={require("../../assets/about-us1.png")} title={"RaumGlück"} />


           <ContentBox
          heading={t('Origins of RaumGlück')}
          desc={t('Origins of RaumGlück des')}
                  
          cls={"shape bottom-shape about-us lft-20"}
        />
          <PhotoBox cls="right bdr-tb-0  shape shape-left" imgUrl={require("../../assets/about-us2.png")} title={"RaumGlück"} />



           <ContentBox
          heading={t('How RaumGlück works')}
          desc={t('How RaumGlück works des')}
                  
          cls={"shape bottom-shape about-us lft-50"}
        />
          <PhotoBox cls="left bdr-tb-0 shape shape-right" imgUrl={require("../../assets/about-us3.png")} title={"RaumGlück"} />



           <ContentBox
          heading={t('What RaumGlück offers')}
          desc={t('What RaumGlück offers des')}
                  
          cls={"shape bottom-shape about-us"}
        />
          <PhotoBox cls="center shape shape-right" imgUrl={require("../../assets/about-us4.png")} title={"RaumGlück"} />
          </div>
    
    </section>
  )
}

export default AboutUs