
import './App.css';
import {Route, Routes} from "react-router-dom"
import Home from './pages/home/Home'
import AboutMe from "./pages/about-me/AboutMe"
import AboutUs from "./pages/about-us/AboutUs"
import Projects from "./pages/projects/Project"
import Header from './components/header/Header';
import {useState } from 'react';
import Footer from './components/footer/Footer';
import ContactUs from './pages/contact/Contact';
import ProjectDetail from './pages/project-detail/ProjectDetail';
import ProductSlider from './pages/product-slider/ProductSlider';
import ScrollButton from './components/scroll-button/ScrollButton';
import LangContext from './langContext';
import ScrollToTop from "./scrollTop"
import i18n from './i18n';

function App() {
  const [headHeight, setHeadHeight] = useState(0);
  const localLang = localStorage.getItem('lang');
  const [active, setActive] = useState(false);
  const [lang, setLang] = useState('en');
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      localStorage.setItem("lang",lng)
        setLang(lng);
      }
  
  
  return (
    <LangContext.Provider value={localLang}>
    <div className="wrapper">
      <div className='container'>
        <Header/>
        
        <main>
           <ScrollToTop />
            <Routes>
             
            <Route path='/' element={<Home />} />
            <Route path='/about-me' element={<AboutMe />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/projects' element={<Projects lang={ lang}/>} />
            <Route path='/contact' element={<ContactUs />} />
              <Route path="/projects/detail/:id" element={<ProjectDetail lang={ lang}/>}></Route>
            <Route path="/projects/detail/:id/slider" element={<ProductSlider lang={ lang} />}></Route>
          </Routes>
        </main>
          <Footer changeLanguage={changeLanguage}  language={lang}/>
        <ScrollButton/>
      </div>
      </div>
      </LangContext.Provider>
  );
}

export default App;
