import React from 'react'
import ContentBox from '../../components/contentBox/ContentBox'
import PhotoBox from '../../components/PhotoBox/PhotoBox';
import { useTranslation } from 'react-i18next'

const AboutMe = () => {
  const { t, i18n } = useTranslation()
  
  return (
    <section>
      <div className='about-us p-b-50'>
        <div className='columns'>
          <ContentBox
            heading={t('ABOUT_ME_HEADING')}
            subHeading={t('ABOUT_ME_SUBHEADING')}
            desc={t('ABOUT_ME_DES')}
            cls={"shape left-shape about-me"}
          />
          <PhotoBox
            imgUrl={require("../../assets/img1.png")}
            title={"RaumGlück"}
            cls={" shape shape-right"} />
            <ContentBox
              heading={t('ABOUT_ME_HEADING_2')}
              subHeading={t('ABOUT_ME_SUBHEADING_2')}
              desc={t('ABOUT_ME_DES_2')}
            
              cls={"shape left-shape about-me mtop-minus-5"}
            />
          <PhotoBox imgUrl={require("../../assets/team2.png")} title={"RaumGlück"} cls={"right shape shape-left"} />
          </div>
      </div>
    </section>
  )
}

export default AboutMe