export default [
    {
    id:'0',  
    name: "Forest House",
    tagline: 'The Forest House: Happy working.',
    desc: "Amidst forests and hills in the alternating area south of Vienna lies the Waldhaus at an altitude of around 900 metres above sea level. The existing structure is more than 200 years old attached by two cubes of large glass elements called 'Max and Moritz'. From the adjacent studio, there is a full view into the forest. This is where the team of RaumGlück works. The structural elements are made of wood and recycled materials.",
    DE_name: "Das Waldhaus",
    DE_tagline:'Das Waldhaus: Glücklich arbeiten.',
    DE_des:"Zwischen Wäldern und Hügeln im niederösterreichischen Wechselgebiet liegt auf rund 900 Meter Seehöhe das Waldhaus. Der noch bestehende Baukörper ist über 200 Jahre alt. Ihm wurden zwei Kuben mit großflächigen Glaselementen mit dem Namen „Max und Moritz“ zur Seite gestellt. Vom angrenzenden Atelier ist der Blick in den Wald frei. Hier arbeitet RaumGlück. Die baulichen Elemente sind aus Holz und recycelten Materialien.",
    img: "/projects/1/cat.jpg",
    cover:'/projects/1/title.png',
    sliders: [
            "/projects/1/d.jpg",
            "/projects/1/e.jpg",
            "/projects/1/f.jpg",
            "/projects/1/g.jpg",
            "/projects/1/h.jpg",
            "/projects/1/i.jpg",
            "/projects/1/k.jpg",
            "/projects/1/l.jpg",
            "/projects/1/m.jpg"
            
        ],

    },
    {
    id:'1',
    name: "Single Flat",
    tagline:"A Single Apartment: An invitation to be happy",
    desc: "Our client is a globetrotter regularly moving back to his roots in Austria and appreciating his small, cleverly furnished apartment in the Mühlviertel, Upper Austria. The functional kitchen behind a sliding door, the washbasin plate made from an old dining table, furniture and pictures from all over the world blend harmoniously into each other. They awaken beautiful memories and invite to be happy.",
    DE_name: "Die Singlewohnung",
    DE_tagline:'Die Singlewohnung: Einladung zum Glücklich sein.',
    DE_des:"Ein Weltenbummler, den es regelmäßig zurück zu seinen Wurzeln nach Österreich zieht, schätzt seine kleine, geschickt eingerichtete Wohnung im Mühlviertel / OÖ. Die funktionelle Küche hinter einer Schiebtüre, die Waschtischplatte aus einem alten Esstisch getischlert, Möbel und Bilder aus aller Welt fügen sich harmonisch ineinander. Sie wecken schöne Erinnerungen und laden zum Glücklich sein ein.",
    img: "/projects/2/cat.jpg",
    cover:'/projects/2/title.jpg',
   sliders: [
            "/projects/2/a.jpg",
            "/projects/2/b.jpg",
            "/projects/2/cat.jpg",
            "/projects/2/title.jpg",
            "/projects/2/d.jpg",
            "/projects/2/e.jpg",
            
        ],

    },
    
    {
    id:'2',
    name: "Villa Rashmi",
    tagline:'Villa Rashmi: That what survived with luck',
    desc: "Built in 1920 in colonial style, the villa is located in the middle of the turbulent megacity of Mumbai. The owner wanted to preserve this architectural jewel. So the villa survived with luck. The interior and exterior spaces have been carefully brought to life with existing, traditional and new, modern elements. The villa is now used privately and for film and television recordings of famous Bollywood productions.",
    DE_name: "Die Villa Rashmi",
    DE_tagline:'Die Villa Rashmi: Mit Glück überlebt.',
    DE_des:"1920 im Kolonialstil erbaut, liegt die Villa mitten in der turbulenten Megacity Mumbai. Die Besitzerin Deval Patel hat das Architukturjuwel erhalten. So hat die Villa mit Glück überlebt. Behutsam und mit Bedacht wurden die Innen- als auch die Außenräume mit bestehenden, traditionellen und neuen, modernen Elementen zu neuem Leben erweckt. Die Villa wird heute privat und für Film- und Fernsehaufnahmen berühmter Bollywoodproduktionen genützt.",
    img: "/projects/3/cat.jpg",
    cover:'/projects/3/title.jpg',
    sliders: [
       "/projects/3/a.jpg",
        "/projects/3/cat.jpg",
        "/projects/3/c.jpg",
        "/projects/3/d.jpg",
        "/projects/3/e.jpg",
        "/projects/3/f.jpg",
        "/projects/3/g.jpg",
        "/projects/3/h.jpg",
        "/projects/3/i.jpg",
        "/projects/3/j.jpg",
        "/projects/3/k.jpg",
        "/projects/3/l.jpg"
    ],

    },
    
    {
    id:'3',
    name: "Vineyard House",
    tagline:'The Vineyard House: Happiness, come in!',
    desc: "The Kellerstöckl is a winecellar with an attached living space, located in the vineyards on the border of Austria and Hungary. The house was built in 1979 in the simple style of the former Eastern European architecture. The redesigned inside and outside makes the Kellerstöckl appear as a contemporary, small house for wine and it's inhabitants. The newly created large panoramic window with a terrace and pergola in front brings the atmosphere of the Eisenberg to the living room. Happiness come in.",
    DE_name: "Das Kellerstöckl",
    DE_tagline:'Das Kellerstöckl: Glück komm herein.',
    DE_des:"Das Kellerstöckl liegt in den Weinbergen an der Grenze zwischen Österreich und Ungarn. Das Haus wurde 1979 im einfachen Stil des ehemaligen Ostblocks erbaut. Die völlige Neugestaltung innen und außen lassen das Kellerstöckl als Schmuckkästchen erscheinen. Das neu geschaffene große Panoramafenster mit vorgelagerter Terrasse und Pergola holt die Stimmung des Eisenbergs bis ins Wohnzimmer. Glück komm herein.",
    img: "/projects/4/cat.jpg",
    cover:'/projects/4/title.jpg',
    sliders: [
        "/projects/4/c.jpg",
        "/projects/4/d.jpg",
        "/projects/4/f.jpg",
        "/projects/4/g.jpg",
        "/projects/4/h.jpg",
        "/projects/4/j.jpg",
        "/projects/4/k.jpg"
    ],

    },
    

    {
    id:'4',
    name: "Montessori School Internationale",
    tagline:'Montessori School Internationale: Learning happiness',
    desc: "The school is located in a former restaurant in a residential area of Mumbai. The floor plan features an open mini playground in the entrance area, two classrooms, toilets, a tea kitchen and an office space for the teachers. The small rooms are made airy and bright, with partitions made of colourful recycled paper tubes. A place where children learn what happiness is.",
    DE_name: "Die Montessori Schule",
    DE_tagline:'Die Montessori Schule: Glück lernen.',
    DE_des:"Die Montessori School Internationale befindet sich in einem ehemaligen Restaurant in einem nordwestlichen Wohnbezirk in Mumbai. Aus den kleinteiligen Räumen wurden luftige und helle Räume. Die Trennwände sind aus farbenfrohen Recyclingpapierröhren. Der langgezogene Grundriss verfügt über einen offenen Mini-Spielplatz im Eingangsbereich, zwei Klassenzimmer, Toiletten, eine Teeküche und einen Büroplatz für die LehrerInnen. Ein Ort, wo Kinder lernen was Glück ist.",
    img: "/projects/5/cat.jpg",
    cover:'/projects/5/title.jpg',
        sliders: [
        "/projects/5/a.jpg",
        "/projects/5/b.jpg",
        "/projects/5/cat.jpg",
        "/projects/5/d.jpg",
        "/projects/5/e.jpg",
        "/projects/5/f.jpg",
        "/projects/5/g.jpg"
    ],

    },
    

    {
    id:'5',    
    name: "Mountain Hut",
    tagline:'The Mountain Hut: Happiness on the mountain',
    desc: "The 25 m2 mountain hut is situated at 1,570 m above sea level in the Hohe Tauern, Salzburg. It is a retreat and starting point for nature experiences; living, sleeping, cooking and eating happen under one roof. A combined oven is the centre of the homely one-room hut. In close cooperation with regional craftsmen, many details have been created with practical benefits that make the happiness on the mountain perfect.",
    DE_name: "Die Berghütte",
    DE_tagline:'Die Berghütte: Glück am Berg.',
    DE_des:"Die Berghütte mit 25 m2 liegt auf 1.570 m Seehöhe in den Hohen Tauern. Sie ist Rückzugsort und Ausgangspunkt für Naturerlebnisse. Wohnen, schlafen, kochen und essen alles unter einem Dach. Ein kombinierter Ofen ist das Zentrum in der heimeligen Ein-Raum-Hütte. In enger Zusammenarbeit mit regionalen HandwerkerInnen sind viele Details mit praktischem Nutzen entstanden, die das Glück am Berg vollkommen machen.",
    img: "/projects/6/cat.jpg",
    cover:'/projects/6/title.jpg',
     sliders: [
        "/projects/6/a.jpg",
        "/projects/6/b.jpg",
        "/projects/6/cat.jpg",
        "/projects/6/d.jpg",
        "/projects/6/e.jpg"
    ],

    },
    

    {
    id:'6',     
    name: "City Apartment",
    tagline:'City Apartment: A small happy space',
    desc: "This small apartment is located in the fifth district of Vienna in a typical 'Gründerzeithaus' built at the beginning of the 20th century. The kitchen space and tiny dining area are situated right in the entrance area to make use of the entire space of the 45 m2 apartment. The living room consists of a big collection of books and objects from all over the world, reflecting the way of life of the residents. The bathroom is located under the loft bed to use the high ceilings at its best.",
    DE_name: "Die Stadtwohnung",
    DE_tagline:'Die Stadtwohnung: glücklich wohnen auf kleinem Raum.',
    DE_des:"Im fünften Wiener Gemeindebezirk wurde eine renovierungsbedürftige „Zimmer, Küche Kabinett“- Wohnung in einem alten Gründerzeithaus neu gestaltet. Die Küchenzeile mit kleinem Essplatz befindet sich gleich im Eingangsbereich, um jeden Platz in der 45 m2 großen Wohnung zu nutzen. Das Wohnzimmer setzt sich aus liebevoll eingerichteten Gegenständen zusammen, die das bunte Leben der Bewohnerlnnen widerspiegeln. Das Bad fand Platz unter dem Hochbett im schmalen, langgezogenen Kabinett. Hier wohnt man glücklich auf kleinem Raum.",
    img: "/projects/7/title.jpg",
    cover:'/projects/7/title.jpg',
    sliders: [
        "/projects/7/a.jpg",
        "/projects/7/b.jpg",
        "/projects/7/c.jpg",
        "/projects/7/d.jpg",
        "/projects/7/e.jpg",
        "/projects/7/f.jpg"
    ],

  },

];
 