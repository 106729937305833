import React,{useState} from 'react'
import { FaArrowCircleUp } from 'react-icons/fa'

const ScrollButton = () => {

  const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
        setVisible(true)
        } 
        else if (scrolled <= 300){
        setVisible(false)
        }
    };
  
  const scrollToTop = (e) => {
    e.preventDefault();
        window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
            in place of 'smooth' */
        });
    };
    
    window.addEventListener('scroll', toggleVisible);

  return (
    <button className='goToTop' style={{ display: visible ? 'inline' : 'none' }}>
        <FaArrowCircleUp
        onClick={scrollToTop} 
          
      />
    </button>
  )
}

export default ScrollButton