import React,{useState} from 'react'
import { NavLink,Link } from 'react-router-dom';
import Logo from "../../assets/logo.png";
import "./Header.style.scss"
import ContentBox from '../../components/contentBox/ContentBox'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t, i18n } = useTranslation()
  const [toggle, setToggle] = useState(false);
  
  return (
    <div className='site-header'>
       <nav className="navbar navbar-expand-lg navbar-light">
    <div className="container">
    <Link className="navbar-brand" to="/">
        <img src={Logo} alt=""/>
    </Link>
    <button className="navbar-toggler" type="button" onClick={()=> setToggle(!toggle)}>
      <span className="navbar-toggler-icon"></span>
    </button>
          <div className={`collapse navbar-collapse ${toggle ? "show":""}`  } id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
            <NavLink className="nav-link" to="/projects" onClick={()=>setToggle(!toggle)}>{t('PROJECTS')}</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/about-me" onClick={()=>setToggle(!toggle)}>{t('TEAM')}</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/about-us" onClick={()=>setToggle(!toggle)}>{t('ABOUT US')}</NavLink>
        </li>

        <li className="nav-item">
          <NavLink  className="nav-link" to="/contact" onClick={()=>setToggle(!toggle)}>{t('CONTACT')}</NavLink>
        </li>
      
      </ul>
     
    </div>
  </div>
</nav>
   </div>
  )
}

export default Header