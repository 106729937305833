import React from 'react'
import RatioPic from "../../assets/1x1.png";

import "./PhotoBox.style.scss";

const PhotoBox = ({imgUrl,title,cls}) => {

    let style = {
     backgroundImage: 'url(' + imgUrl + ')'
    }
    
  return (
      <div className={`photo-box ${cls}`}>
      <img src={RatioPic} style={style} alt="img" />
      
    </div>
  )
}

export default PhotoBox