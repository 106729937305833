import React,{useEffect, useState} from 'react'
import i18n from "../../i18n"
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import GoogleTranslate from '../GoogleTranslate';
import { useTranslation } from 'react-i18next'

import "./Footer.style.scss";
// || path !== "/about" || path !== "/contact" || path !== "/about-me" || path !== "/projects" 
const Footer = ({changeLanguage,language}) => {
  const { t, i18n } = useTranslation()
  const [showBack, setShowBack] = useState(false);
  const [lang, setLang] = useState('en');
  const location = useLocation();
  const path = location.pathname;

  const { detail } = useParams();

  const navigate = useNavigate();
  
  useEffect(() => {
    if (detail) {
      setShowBack(true);
      console.log(setShowBack);
    }
  }, [])

  
  return (
    <footer className='site-footer'>
     
      <div className='lang-btn'>
        <GoogleTranslate/>
        <button className={language == "de" ? 'active':''} onClick={() => changeLanguage('de')}>de</button>
        <button className={language == "en" ? 'active':''} onClick={() => changeLanguage('en')}>en</button>
      </div>
      <div className="right">
        {
        path.indexOf("detail") > -1 &&  <button className="back-btn" onClick={() => navigate(-1)}>BACK</button>
        }
      </div>
       {
          path.indexOf("contact") > -1 &&  <p className='web-owner'>Website by <a href="https://www.instagram.com/neel_buddhadev/" target={"_blank"}>Neel Buddhadev.</a><br/>
          Logo design by <span>Marlene Pollhammer.</span> </p>
        }
    </footer>
  )
}

export default Footer