import React from 'react'
import "./ContentBox.styles.scss"
const ContentBox = ({ heading,subHeading, desc, author, cls}) => {
  return (
      <section className={`contentBox ${cls}`}>
          <div className='content'>
        <h2>{heading} <span className='sub-head'>{subHeading}</span></h2>
              <h4>{author}</h4>
              <p>{desc}</p>
              
         </div>
        </section>
  )
}

export default ContentBox